import React from "react";
import PropTypes from "prop-types";
import TextListItem from "./text-list-item";

const TextListService = props => {
  return (
    <div className="text-list-area">
      {props.children.map((child, idx) => {
        return (
          <TextListItem
            description={child.description}
            headline={child.title}
            key={idx}
          ></TextListItem>
        );
      })}
    </div>
  );
};

TextListService.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired
};

export default TextListService;
