import React from "react";
import PropTypes from "prop-types";

const TextListItem = props => {
  const { headline, description } = props;
  return (
    <section className="text-list-section">
      <h3 className="headline">{headline}</h3>
      <p className="description">{description}</p>
    </section>
  );
};

TextListItem.propTypes = {
  headline: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default TextListItem;
