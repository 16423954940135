import React from "react";
import PropTypes from "prop-types";

const Testimonial = props => {
  const { quote, userName, company, presentPost } = props;
  return (
    <div className="blockquote-section">
      <blockquote>
        <p>"{quote}"</p>
        <cite>
          <span className="name">{userName}</span>
          <span className="company">
            {presentPost}
            <br />
            {company}
          </span>
        </cite>
      </blockquote>
    </div>
  );
};

Testimonial.propTypes = {
  quote: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  presentPost: PropTypes.string.isRequired
};

export default Testimonial;
