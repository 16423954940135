import React, { Component } from "react";
import PropTypes from "prop-types";

class AccordionItem extends Component {
  handleOpenCloseAccordion = () => {
    if (this.state.open) {
      document.body.classList.toggle("overflow-hidden");
      this.setState({
        open: false,
        accordionClass: ""
      });
    } else {
      document.body.classList.toggle("overflow-hidden");
      this.setState({
        open: true,
        accordionClass: "is--opened"
      });
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      accordionClass: ""
    };
  }

  render() {
    const { accordionNumber, title, description, children } = this.props;
    return (
      <>
        <div className={`accordion-head ${this.state.accordionClass}`}>
          <div className="container container--fluid container--bordered">
            <div className="accordion-caption">
              <span className="accordion-number">{accordionNumber}</span>
              <div className="accordion-intro">
                <h2 className="accordion-title">{title}</h2>
                <p>{description}</p>
              </div>
              <span
                className="accordion-toggler"
                onClick={this.handleOpenCloseAccordion}
                role="presentation"
              />
            </div>
          </div>
        </div>
        <div className={`accordion-body ${this.state.accordionClass}`}>
          <div className="holder">
            <div className="container container--fluid container--light-bordered">
              <div className="accordion-caption">
                <span className="accordion-number">{accordionNumber} </span>
                <h2 className="accordion-title">{title}</h2>
                <span
                  className="accordion-toggler"
                  onClick={this.handleOpenCloseAccordion}
                  role="presentation"
                />
              </div>
              {children}
            </div>
          </div>
        </div>
      </>
    );
  }
}

AccordionItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  accordionNumber: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default AccordionItem;
