import React from "react";
import PropTypes from "prop-types";
import Link from "../link";

const ReadMoreTitle = props => {
  const {
    title,
    category,
    secondaryLinkTitle,
    secondaryLinkPath,
    primaryLinkPath
  } = props;
  return (
    <section className="section title-section read-more-section">
      <div className="container container--bordered">
        <div className="box heading">
          <h2 className="h1 indent-text complex-heading footer-heading">
            <Link to={primaryLinkPath} tabIndex="0" className="title-link">
              <sup>{category}</sup>
              {title}
            </Link>
            <Link to={secondaryLinkPath} tabIndex="0">
              {secondaryLinkTitle}
            </Link>
          </h2>
        </div>
      </div>
    </section>
  );
};

ReadMoreTitle.propTypes = {
  title: PropTypes.string.isRequired,
  primaryLinkPath: PropTypes.string.isRequired,
  secondaryLinkTitle: PropTypes.string.isRequired,
  secondaryLinkPath: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired
};

export default ReadMoreTitle;
