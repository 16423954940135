import React from "react";
import { Helmet } from "react-helmet";
import { navigate } from "gatsby-link";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Accordion from "../components/accordion/accordion";
import Headline from "../components/text-elements/headline";
import ReadMoreTitle from "../components/text-elements/read-more-title";

const ServicesPage = ({ data }) => {
  const setting = data.takeshape.getSetting;
  const service = data.takeshape.getService;
  if (
    (!service.enabled && process.env.GATSBY_ENVIRONMENT !== "staging") ||
    (!service.enableOnStaging && process.env.GATSBY_ENVIRONMENT === "staging")
  ) {
    if (typeof window !== "undefined") {
      navigate("/");
    }
  }
  return (
    <>
      <Helmet
        bodyAttributes={{
          class: "footer-offset alt-header services-page",
        }}
      />
      <Layout headerClass="header--dark">
        <Seo title={service.pageTitle} description={setting.seoContent} />
        <Headline headline={service.pageTitle} description={service.title} />
        <Accordion services={service.services} />
        <ReadMoreTitle
          title={service.missionTitle}
          primaryLinkPath={service.missionLink}
          secondaryLinkTitle={service.missionSecondaryTitle}
          secondaryLinkPath={service.missionSecondaryLink}
          category={service.missionCategory}
        />
      </Layout>
    </>
  );
};

export default ServicesPage;

export const query = graphql`
  query {
    takeshape {
      getSetting {
        seoContent
      }
      getService {
        enabled
        enableOnStaging
        missionCategory
        missionLink
        missionSecondaryLink
        missionSecondaryTitle
        missionTitle
        pageTitle
        services {
          description
          detailDescription
          serviceList {
            description
            title
          }
          slides {
            description
            image {
              path
              title
            }
          }
          testimonial {
            company
            presentPost
            quote
            userName
          }
          title
        }
        title
      }
    }
  }
`;
