import React from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  Image,
  ButtonBack,
  ButtonNext,
  DotGroup,
  Dot
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import AccordionItem from "./accordion-item";
import Testimonial from "../testimonial";
import TextListService from "../text-elements/text-list-service";
import { getImageUrl } from "takeshape-routing";

const Accordion = ({services}) => {
  return (
    <>
      {services.map((service, idx) => 
        <AccordionItem
          title={service.title}
          description={service.description}
          accordionNumber={`${idx + 1}/${services.length}`}
          key={idx}
        >
          <div className="section section-columns">
            <div className="row">
              <div className="row__column  description-section">
                <p>
                  {service.detailDescription}
                </p>                
              </div>
              <div className="row__column slider-section left--bordered">
                <CarouselProvider
                  totalSlides={service.slides.length}
                  aspect-ratio={16 / 9}
                  dragEnabled={false}
                >
                  <Slider>
                    {service.slides.map((slide, idx) => 
                      <Slide index={idx + 1} key={idx}>
                        <div className="slider-holder">
                          <div className="row">
                            <div className="row__column img-wrap right--bordered">
                              <Image hasMasterSpinner={false} src={getImageUrl(slide.image.path, {fit: "crop", auto: "compress"})} />
                            </div>
                            <div className="row__column content-wrap">
                              <div className="holder">
                                <p>
                                  {slide.description}
                                </p>
                                <span className="order-list">
                                  {idx + 1}<i className="divider">/</i>{service.slides.length}
                                </span>
                                <div className="btn-wrap">
                                  <ButtonBack />
                                  <ButtonNext />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Slide>
                    )}
                  </Slider>
                  <div className="btn-wrap btn-wrap--mobile">
                    <ButtonBack />
                    <DotGroup dotNumbers={true}>
                      <div className="dot-last-indicator">
                        <Dot slide={service.slides.length}>
                          <span>{service.slides.length}</span>
                        </Dot>
                      </div>
                    </DotGroup>
                    <ButtonNext />
                  </div>
                </CarouselProvider>
              </div>
            </div>
            <div className="row row-reverse">
              <div className="row__column width-50 left--bordered">
                <TextListService children={service.serviceList} />
              </div>
              <div className="row__column width-50">
                <Testimonial
                  quote={service.testimonial.quote}
                  userName={service.testimonial.userName}
                  company={service.testimonial.company}
                  presentPost={service.testimonial.presentPost}
                />
              </div>
            </div>
          </div>
        </AccordionItem>
      )}      
    </>
  );
};

export default Accordion;
